body {
    background: #FFF0F5;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
}

ol {
	display: flex;
	flex-direction: column;
	list-style: none;
	max-height: 250px;
	flex-wrap: wrap;
}

.base-layer {
    background: #E6E6FA;
    max-width: 85%;
    margin: 25px auto 25px;
    padding: 15px 15px;
    border-radius: 3px;
    text-align: center;
}

.bottom {
    text-align: center;
}

.btn.button--general {
	background-color: #fdf9f3;
	border: 1px solid #0f1111;
	border-radius: 3px;
	color: #0f1111;
	cursor: pointer;
	display: inline-block;
	padding: 1px 6px 1px 6px;
	text-align: center;
	text-overflow: ellipsis;
}

.btn.button--general:hover {
	background-color: #f0f0f0;
	border: 1px solid #0f1111;
	color: #0f1111;
}

.carry-info--disabled {
    opacity: 0;
}

.divider--m {
	display: block;
    margin: 30px auto;
    width: 175px;
	border: none;
  	height: 3px;
	background-color: #546E7A;
}

.main {
    line-height: 1.15;
    padding: 15px 15px;
    border-radius: 3px;
    font-size: 14px;
    text-align: left;
}

.post-code {
    background: #fdf9f3;
    line-height: 1.2;
	font-size: 10px;
    font-family: "Lucida Console", monospace;
    padding: 5px 5px;
    border-radius: 3px;
    text-align: left;
}
